import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_1_day_no_overlay from '../../../../../../../common/src/assets/image/example_1_day_no_overlay.png';
import template_options_1_day_no_overlay from '../../../../../../../common/src/assets/image/template_options_1_day_no_overlay.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The 1 Day/Page Without Overlay template style generates a calendar
        showing 1 day per page. Events and tasks will be placed within their
        corresponding time slot rows and within the associated calendar column,
        rather than being overlaid into the same columns.
      </p>
      <p>
        <Image alt="" src={example_1_day_no_overlay} />
      </p>
      <h2>Template Settings</h2>
      <p>
        When creating a calendar using the 1 Day/Page Without Overlay template,
        the following options are provided...
      </p>
      <Image
        alt=""
        src={template_options_1_day_no_overlay}
        style={{ width: 448 }}
      />
      <br />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of days</strong>
            </td>
            <td>
              The total number of days to include in the output. Each day will
              generate to its own worksheet or page.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Timeslots</strong>
            </td>
            <td>
              A timeslot range and interval can be specified. The output will
              only show times between the specified start and end values. Each
              row will correspond to the specified timeslot length, such as 30
              minutes.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max. columns per calendar</strong>
            </td>
            <td>
              Each calendar data source in the generated calendar will have up
              to the specified number of sub-columns. These columns are used in
              cases where there are multiple events or tasks that occur in the
              same timeslot for the same calendar data source. In other words,
              if you set the maximum number of columns to 3, the calendar column
              will be able to display up to 3 overlapping events or tasks
              occurring in the same time slot.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max. all-day rows</strong>
            </td>
            <td>
              All-day events and tasks will be shown in an all-day area of the
              generated calendar, which is usually above the timeslot rows. This
              value controls how many events or tasks can be shown in the
              all-day area. Each event or task will take up one row.
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <strong>All-day text behavior</strong>
            </td>
            <td>
              The text behavior of items shown in the all-day area. Options are{' '}
              <strong>Wrap text</strong>, <strong>Don't wrap text</strong>, and{' '}
              <strong>Shrink text to fit</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time </strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>When checked, event or task end times will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show time on separate line</strong>
            </td>
            <td>
              When checked, event or task times will be shown on a separate
              line, above the title.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show location on separate line</strong>
            </td>
            <td>
              When checked, event or task locations will be shown on a separate
              line, below the title and above the description.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show description</strong>
            </td>
            <td>
              When checked, descriptions will be included in event and task
              blocks (up to the available space).
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show blank line above description </strong>
            </td>
            <td>
              When checked, a blank line will be shown above event and task
              descriptions, to help differentiate descriptions from title and
              location values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item titles </strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from location and description values.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-Without-Overlay"
      commentsId="commentsplus_post_183_591"
      title="1 Day/Page Without Overlay Template"
      description="The 1 Day/Page Without Overlay template style generates a calendar showing 1 day per page with separate columns for each calendar data source."
      keywords="day template, time slots, calendar columns"
      content={content}
    />
  );
};

export default Documentation;
